import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {DefaultWeek} from "@/models/defaultWeek";
import {isNotIntegerOrNull} from "@/main";

export const useDefaultWeekStore = defineStore("defaultWeek", {
    state: () => ({
        defaultWeeks: null,
        defaultWeek: DefaultWeek,
        spinnerDefaultWeek: false,
        queryParams: {search: '', page: 1},
        PERIODICITIES: {
            'yes_yes_yes_yes': 'Tutte le Settimane',
            'yes_no_yes_no': 'Una volta ogni due settimane',
            'yes_no_no_no': 'Una volta ogni quattro settimane',
            'yes_yes_no_no': 'Due volte ogni quattro settimane',
            'yes_yes_yes_no': 'Tre volte ogni quattro settimane',
        },
        DEFAULT_PERIODICITY: 'yes_yes_yes_yes',
        paginationDefaultWeek: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setDefaultWeekPagination(defaultWeekPagination) {
            this.paginationDefaultWeek = defaultWeekPagination
        },

        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    search: '',
                    page: 1
                }
            }
        },

        getDefaultWeek(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerDefaultWeek = true
                axios.get('api/default-weeks/' + id + '/', config)
                    .then(res => {
                        this.defaultWeek = res.data
                        this.spinnerDefaultWeek = false
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerDefaultWeek = false
                    })
            })
        },
        getDefaultWeeks(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationDefaultWeek
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/default-weeks/')

                this.spinnerDefaultWeek = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.defaultWeeks = res.data
                            this.spinnerDefaultWeek = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerDefaultWeek = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerDefaultWeek = false
                            this.defaultWeeks = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerDefaultWeek = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                }

            })
        },
        editDefaultWeek(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/default-weeks/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerDefaultWeek = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerDefaultWeek = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerDefaultWeek = false
                    })
            })
        },
        addDefaultWeek(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/default-weeks/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerDefaultWeek = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerDefaultWeek = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerDefaultWeek = false
                    })
            })

        },

        deleteDefaultWeek(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/default-weeks/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerDefaultWeek = false
                    })
            })
        },
    },
    getters: {
        defaultWeeksGetters: state => {
            return state.defaultWeeks
        },
        defaultWeekGetters: state => {
            return state.defaultWeek
        },
        spinnerDefaultWeekGetters: state => {
            return state.spinnerDefaultWeek
        },
        paginationDefaultWeekGetters: state => {
            return state.paginationDefaultWeek
        }
    }
})