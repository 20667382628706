import {Service} from "@/models/service";
import {User} from "@/models/user";
import {Client} from "@/models/client";

export class ShiftTimetableCreateUpdate {
    constructor(id = 0, client = 0, service = 0, employee = 0, starts_at = null,
                ends_at = null, repetition = 1, frequency = 'day',
                date = null, shift_name = '',
                datetime_range = [{datetime_start: '', datetime_end: ''}],
                client_dict = new Client(), notes = '',
                service_dict = new Service(), employee_dict = new User(),
                date_str = '', update_type = '', employee_permission_slot = null, datetime_start = null, datetime_end = null) {
        this.id = id
        this.client = client
        this.service = service
        this.employee = employee
        this.starts_at = starts_at
        this.ends_at = ends_at
        this.date = date
        this.repetition = repetition
        this.frequency = frequency
        this.shift_name = shift_name
        this.client_dict = client_dict
        this.service_dict = service_dict
        this.employee_dict = employee_dict
        this.date_str = date_str
        this.datetime_range = datetime_range
        this.notes = notes
        this.update_type = update_type
        this.employee_permission_slot = employee_permission_slot
        this.datetime_start = datetime_start
        this.datetime_end = datetime_end
    }
}