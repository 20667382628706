<template>
  <router-view v-if="!storeAuth.isAuthenticated"></router-view>
  <div class="container-view" v-if="storeAuth.isAuthenticated">
    <div id="sidebar-app" class="sidebar" v-if="storeAuth.isAuthenticated">
      <ComponentSideBar></ComponentSideBar>
    </div>
    <div class="container-content-view-app">
      <ComponentNavBar></ComponentNavBar>
      <div class="container-content-router-view mw-100">
        <router-view></router-view>
      </div>
    </div>
    <div v-if="storeAuth.isAuthenticated && storeApi.overlayPopup" class="overlay">
      <Transition name="slide-fade">
        <ComponentPopup v-if="storeAuth.isAuthenticated && storeApi.overlayPopup"></ComponentPopup>
      </Transition>
    </div>
    <div class="position-fixed fixed-bottom m-3">
      <b-alert variant="danger" :show="storeApi.errorNetworkGetters">Connessione assente &nbsp;&nbsp;
        <span @click="storeApi.errorNetwork=false"><b role="button">X</b></span></b-alert>
    </div>
  </div>
</template>

<script>

import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {BAlert} from "bootstrap-vue-3";
import ComponentPopup from "@/components/popup/ComponentPopup.vue";
import ComponentSideBar from "@/components/sidebar/ComponentSideBar.vue";
import ComponentNavBar from "@/components/navbar/ComponentNavBar.vue";

export default {
  name: 'App',
  components: {
    ComponentNavBar,
    ComponentSideBar,
    ComponentPopup,
    BAlert,
  },
  setup() {
    const storeAuth = useAuthStore()
    const storeApi = useApiSettingStore()
    return {
      storeAuth,
      storeApi
    }
  },
  created() {
    useApiSettingStore().errorNetwork = false
    useApiSettingStore().overlayPopup = false
    useApiSettingStore().typePopup = ''
    useApiSettingStore().popUpMessage = null
  },
  beforeUnmount() {
    // Stop the interval when the component is about to be unmounted
    // clearInterval(this.intervalId);
  },
  mounted() {
    // Start the interval when the component is mounted
    // this.intervalId = setInterval(() => {
    //   if (useAuthStore().isAuthenticated) {
    //     useElectronicBadgeStore().electronicBadgeTrigger().then()
    //   }
    // }, 1800000); // 1000 milliseconds = 1 second
    let sidebar = document.getElementById('sidebar-app')
    if (sidebar) {
      if (window.matchMedia("(orientation: portrait)").matches) {
        sidebar.style.width = '0vw'
        let toggle_open = document.getElementsByClassName('toggle-open')[0]
        if (toggle_open)
          toggle_open.style.display = 'block'
      } else {
        let toggle_open = document.getElementsByClassName('toggle-open')[0]
        toggle_open.style.display = 'none'
        let sidebar = document.getElementsByClassName('toggle-class')[0]
        sidebar.style.display = 'block'
        sidebar.style.width = 'auto'
      }
    }
  }
}
</script>

<style>
.container-content-view-app {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fixed-bottom {
  left: auto !important;
}
</style>
