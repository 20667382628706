export class Client {
    constructor(id = 0, name = '', phone_number = '', address = '', geolocation = '', work_location_radius = '') {
        this.id = id;
        this.name = name;
        this.phone_number = phone_number;
        this.address = address
        this.geolocation = geolocation
        this.work_location_radius = work_location_radius
    }
}

export class CreateShiftTimetableFromDefaultWeek {
    constructor(id = 0, client = 0, service = 0, employee = 0, starts_date = '', end_date = '',
                default_week = 0, name = '', notes = '', periodicity = 'yes_yes_yes_yes', employee_dict = {},
                client_dict = {}, service_dict = {}) {
        this.id = id
        this.client = client
        this.service = service
        this.employee = employee
        this.starts_date = starts_date
        this.ends_date = end_date
        this.default_week = default_week
        this.shift_name = name
        this.notes = notes
        this.periodicity = periodicity
        this.employee_dict = employee_dict
        this.service_dict = service_dict
        this.client_dict = client_dict
    }
}