import axios from 'axios'
import {defineStore} from 'pinia'


export const useAuthStore = defineStore({
    id: 'auth',


    state: () => ({
        user: {user_id: 0, token: ''},
        errorToken: '',
        error: '',
        navList: [''],
        navIndex: 0,
        userPermissions: {
            'profile': [],
            'home': [],
            'employees': [],
            'users': [],
            'services': [],
            'clients': [],
            'shifts': [],
            'electronic_badges': [],
            'electronic_badge_employee': [],
            'electronic_badge_calendar': [],
            'default_weeks': [],
            'request': [],
            'permissions': [],
            'employee_permission': [],
            'employee_calendar': [],
            'calendar': [],
            'dashboard': [],
            'total_hours': [],
            'settings': [],
            'transactions': [],
            'holidays': []
        }
    }),
    actions: {
        pushNavList(element) {
            this.navList.slice(0, this.navIndex + 1)
            this.navList.push(element)
            this.navIndex = this.navList.length - 1
        },
        previousPath() {
            if (this.navIndex > 0) {
                this.navIndex -= 1
            }
        },
        nextPath() {
            if (this.navIndex < this.navList.length - 1) {
                this.navIndex -= 1
            }
        },
        login(user) {
            return new Promise((resolve, reject) => {
                axios.post("auth/", user)
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                        const config = {
                            headers: {
                                Authorization: 'Token ' + this.user.token
                            }
                        }
                        new Promise(() => {
                            axios.get('api/get-permissions', config).then(res => this.userPermissions = res.data)
                        }).then()
                    })
                    .catch(err => {
                        if (err.response) {
                            this.error = err.response.data
                        }
                        reject(err)
                    })
            })
        }
    },

    getters: {
        isAuthenticated: state => {
            return !!(state.user && state.user.token)
        },
        userGetters: state => {
            return state.user
        },
        errorGetters: state => {
            return state.error
        },
        errorTokenGetters: state => {
            return state.errorToken
        },
        userPermissionsGetters: state => {
            return state.userPermissions
        }
    }
})


