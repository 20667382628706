import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {isNotIntegerOrNull} from "@/main";
import moment from "moment";


const currentDate = new Date()
const startRange = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
const endRange = new Date(newDate);
endRange.setDate(newDate.getDate() - 1);
export const usePermissionStore = defineStore("permissions", {
    state: () => ({
        permissions: null,
        permission: {},
        permissionTypes: [],
        spinnerPermission: false,
        openMap: false,
        queryParams: {
            start_day__gte: moment(startRange).format('YYYY-MM-DD'),
            end_day__lte: moment(endRange).format('YYYY-MM-DD'),
            search: '',
            page: 1,
        },
        paginationPermission: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                const currentDate = new Date()
                const startRange = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const newDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
                const endRange = new Date(newDate);
                endRange.setDate(newDate.getDate() - 1);
                this.queryParams = {
                    start_day__gte: moment(startRange).format('YYYY-MM-DD'),
                    end_day__lte: moment(endRange).format('YYYY-MM-DD'),
                    search: '',
                    page: 1
                }
            }
        },

        setPermissionPagination(permissionPagination) {
            this.paginationPermission = permissionPagination
        },

        getPermission(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerPermission = true
                axios.get('api/permissions/' + id + '/', config)
                    .then(res => {
                        this.permission = res.data
                        resolve(res)
                        this.spinnerPermission = false

                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerPermission = false
                        reject(err)
                    })
            })
        },
        getPermissions(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            payload.queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationPermission
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/permissions/')

                this.spinnerPermission = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.permissions = res.data
                            this.spinnerPermission = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerPermission = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerPermission = false
                            this.permissions = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerPermission = false
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            reject(err)
                        })
                }

            })
        },

        editPermission(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/permissions/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerPermission = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerPermission = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerPermission = false
                    })
            })
        },

        addPermission(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/permissions/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerPermission = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerPermission = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerPermission = false
                    })
            })

        },

        rejectOrAccept(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/permissions/' + id + '/reject-or-accept/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerPermission = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerPermission = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerPermission = false
                    })
            })
        },

        deletePermission(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/permissions/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerPermission = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerPermission = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerPermission = false
                    })
            })
        },

        getPermissionTypes() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/permissions/types/')

                axios.get(url.pathname, config)
                    .then(res => {
                        this.permissionTypes = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerDefaultWeek = false
                    })
            })
        },

    },
    getters: {
        permissionsGetters: state => {
            return state.permissions
        },
        permissionGetters: state => {
            return state.permission
        },
        spinnerPermissionGetters: state => {
            return state.spinnerPermission
        },
        permissionTypesGetters: state => {
            return state.permissionTypes
        },
        paginationPermissionGetters: state => {
            return state.paginationPermission
        }
    }
})