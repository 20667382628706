import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {ElectronicBadge} from "@/models/electronicBadge.js";
import {isNotIntegerOrNull} from "@/main";
import moment from "moment";
import {format} from "date-fns";

export const useElectronicBadgeStore = defineStore("electronicBadges", {
    state: () => ({
        MAPS_API_KEY: '',
        electronicBadges: null,
        electronicBadge: ElectronicBadge,
        electronicBadgeUserData: {},
        spinnerElectronicBadge: false,
        queryParams: {
            search: '', page: 1, is_approved: '', datetime_checked_in__date__gte: moment().format('YYYY-MM-DD'),
        },
        queryParamsBadgeCalendar: {view: 'week', focus: format(new Date, 'yyyy-MM-dd')},
        paginationElectronicBadge: 10,
    }),
    actions: {
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    search: '', is_approved: '',
                    page: 1,
                    datetime_checked_in__date__gte: moment().format('YYYY-MM-DD'),
                }
            }
        },
        setQueryParamsBadgeCalendar(queryParams = null) {
            if (queryParams) {
                this.queryParamsBadgeCalendar = queryParams
            } else {
                this.queryParamsBadgeCalendar = {view: 'week', focus: format(new Date, 'yyyy-MM-dd')}
            }
        },
        getMapsJavascriptApiKey() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/get-maps-api-key', config)
                    .then(res => {
                        this.MAPS_API_KEY = res.data
                        resolve(res)
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    reject(err)
                })
            })
        },

        setElectronicBadgePagination(electronicBadgePagination) {
            this.paginationElectronicBadge = electronicBadgePagination
        },

        getUserElectronicBadgeTrigger() {
            return new Promise((resolve) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/employee-badge/trigger-badge-check/', config)
                    .then(res => {
                        resolve(res)
                    }, () => {
                    })
                    .catch(() => {
                    })
            })

        },

        electronicBadgeTrigger() {
            return new Promise((resolve) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/employee-badge/trigger-badge-check-all/', config)
                    .then(res => {
                        resolve(res)
                    }, () => {
                    })
                    .catch(() => {
                    })
            })

        },


        getUserElectronicBadgeData() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerElectronicBadge = true
                axios.get('api/employee-badge/electronic-badge-user-data/', config)
                    .then(res => {
                        resolve(res)
                        this.spinnerElectronicBadge = false
                    }, reason => {
                        resolve(reason)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })

        },

        getElectronicBadge(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerElectronicBadge = true
                axios.get('api/electronic-badges/' + id + '/', config)
                    .then(res => {
                        this.electronicBadge = res.data
                        resolve(res)
                        this.spinnerElectronicBadge = false

                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            this.spinnerElectronicBadge = false
                        }
                        reject(err)
                    })
            })
        },
        getElectronicBadges(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            payload.queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationElectronicBadge
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/electronic-badges/')

                this.spinnerElectronicBadge = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.electronicBadges = res.data
                            this.spinnerElectronicBadge = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerElectronicBadge = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerElectronicBadge = false
                            this.electronicBadges = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerElectronicBadge = false
                            reject(err)
                        })
                }

            })
        },
        createElectronicBadge(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/electronic-badges/', payload, config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                            this.spinnerElectronicBadge = false
                        }
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        editElectronicBadge(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/electronic-badges/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                            this.spinnerElectronicBadge = false
                        }
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        toggleApproveElectronicBadge(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/electronic-badges/' + id + '/toggle-approve/', payload, config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                            this.spinnerElectronicBadge = false
                        }
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        editEmployeeBadge(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/employee-badge/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                            this.spinnerElectronicBadge = false
                        }
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        addElectronicBadge(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/employee-badge/', payload, config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                            this.spinnerElectronicBadge = false
                        }
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })

        },

        deleteElectronicBadge(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/electronic-badges/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        deleteElectronicBadgeEmployee(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/employee-badge/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerElectronicBadge = false
                    })
            })
        },

        getEmployeeElectronicBadgeCalendar(id, payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            payload.queryParams.page_size = null
            payload.queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/employee-electronic-badge-calendar/' + id + '/')
                this.spinnerElectronicBadge = true
                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axios.get(url.pathname + url.search, config)
                    .then(res => {
                        resolve(res)
                        this.spinnerElectronicBadge = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerElectronicBadge = false
                        reject(err)
                    })

            })
        },

    },
    getters: {
        electronicBadgesGetters: state => {
            return state.electronicBadges
        },
        electronicBadgeGetters: state => {
            return state.electronicBadge
        },
        electronicBadgeUserDataGetters: state => {
            return state.electronicBadgeUserData
        },
        spinnerElectronicBadgeGetters: state => {
            return state.spinnerElectronicBadge
        },
        paginationElectronicBadgeGetters: state => {
            return state.paginationElectronicBadge
        }
    }
})