import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        apiSettings: null,
        error500: '',
        errorNetwork: false,
        typePopup: '',
        overlayPopup: false,
        popUpMessage: '',
        successCode: 'success',
        errorCode: 'danger',
        successMessage: 'Operazione completata con successo',
        errorMessage: 'Qualcosa non ha funzionato. Controlla la connessione rete o riprova più tardi.',
        errorMessage404: 'Qualcosa non ha funzionato. Controlla la connessione rete o riprova più tardi.',
        baseTimezone: {en: 'Europe/Rome', it: 'Europa/Roma'},
        toggleSidebar: false,
        pagination: [null, 10, 20, 50, 100],
    }),
    actions: {

        setSuccessOrErrorMessage(status, message) {
            this.typePopup = status
            this.overlayPopup = true
            this.popUpMessage = message
            setTimeout(() => {
                this.overlayPopup = false
                this.typePopup = ''
                this.popUpMessage = null
            }, 2000)
        },

        getGeneralSearch(search) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/get-general-search/' + search + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

    },

    getters: {
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        },
        overlayPopupGetters: state => {
            return state.overlayPopup
        },
        typePopupGetters: state => {
            return state.typePopup
        },
        popUpMessageGetters: state => {
            return state.popUpMessage
        },
        toggleSidebarGetters: state => {
            return state.toggleSidebar
        },
        paginationGetters: state => {
            return state.pagination
        }
    }
})


