export class DefaultWeek {
    constructor(id = 0, name = '', monday = '', tuesday = '', wednesday = '', thursday = '',
                friday = '', saturday = '', sunday = '', total_hours = 0, frontend_json = {}) {
        this.id = id;
        this.name = name;
        this.monday = monday;
        this.tuesday = tuesday;
        this.wednesday = wednesday;
        this.thursday = thursday;
        this.friday = friday;
        this.saturday = saturday;
        this.sunday = sunday;
        this.total_hours = total_hours
        this.frontend_json = frontend_json
    }
}