import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import router from "@/router";
import {User} from "@/models/user";
import profileImage from "@/assets/base/profile.jpg";
import {isNotIntegerOrNull} from "@/main";

export const useUserStore = defineStore("user", {
    state: () => ({
        users: null,
        user: User,
        spinnerUser: false,
        userTypes: null,
        paginationUser: Number(process.env['VUE_APP_PER_PAGE']),
        editCredentials: false,
        editPersonalData: false,
        editProfile: false,
        editDocumentId: false,
        editCV: false,
        editIban: false,
        editEmployeeData: false,
        editUserType: false,
        defaultWeekSelectedEmployee: null,
        queryParams: {user_type__title: '', search: '', page: 1},
    }),
    actions: {
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    user_type__title: '', search: '', page: 1
                }
            }
        },

        setUserPagination(userPagination) {
            this.paginationUser = userPagination
        },

        setDefaultWeekSelectedEmployee(editDefaultWeekSelectedEmployee = null) {
            this.defaultWeekSelectedEmployee = editDefaultWeekSelectedEmployee
        },

        toggleEditCredentials(editCredentials = null) {
            this.editCredentials = editCredentials === null ? !this.editCredentials : editCredentials
            if (this.editCredentials) {
                this.editEmployeeData = false
                this.editIban = false
                this.editPersonalData = false
                this.editProfile = false
                this.editDocumentId = false
                this.editCV = false
                this.editUserType = false
            }
        },

        toggleEditPersonalData(editPersonalData = null) {
            this.editPersonalData = editPersonalData === null ? !this.editPersonalData : editPersonalData
            if (this.editPersonalData) {
                this.editEmployeeData = false
                this.editIban = false
                this.editCredentials = false
                this.editProfile = false
                this.editDocumentId = false
                this.editCV = false
                this.editUserType = false
            }
        },

        toggleEditEmployee(editToggleEmployee = null) {
            this.editEmployeeData = editToggleEmployee === null ? !this.editEmployeeData : editToggleEmployee
            if (this.editEmployeeData) {
                this.editCredentials = false
                this.editIban = false
                this.editPersonalData = false
                this.editProfile = false
                this.editDocumentId = false
                this.editCV = false
                this.editUserType = false
            }
        },
        toggleEditProfile(editProfile = null) {
            this.editProfile = editProfile === null ? !this.editProfile : editProfile
            if (this.editProfile) {
                this.editEmployeeData = false
                this.editIban = false
                this.editCredentials = false
                this.editPersonalData = false
                this.editDocumentId = false
                this.editCV = false
                this.editUserType = false
            }
        },
        toggleEditDocumentId(editDocumentId = null) {
            this.editDocumentId = editDocumentId === null ? !this.editDocumentId : editDocumentId
            if (this.editDocumentId) {
                this.editEmployeeData = false
                this.editIban = false
                this.editCV = false
                this.editCredentials = false
                this.editProfile = false
                this.editPersonalData = false
                this.editUserType = false
            }
        },
        toggleEditIban(editIban = null) {
            this.editIban = editIban === null ? !this.editIban : editIban
            if (this.editIban) {
                this.editEmployeeData = false
                this.editDocumentId = false
                this.editCV = false
                this.editCredentials = false
                this.editProfile = false
                this.editPersonalData = false
                this.editUserType = false
            }
        },
        toggleEditCV(editCV = null) {
            this.editCV = editCV === null ? !this.editCV : editCV
            if (this.editCV) {
                this.editEmployeeData = false
                this.editIban = false
                this.editDocumentId = false
                this.editCredentials = false
                this.editProfile = false
                this.editPersonalData = false
                this.editUserType = false
            }
        },
        toggleEditUserType(editUserType = null) {
            this.editUserType = editUserType === null ? !this.editUserType : editUserType
            if (this.editUserType) {
                this.editEmployeeData = false
                this.editIban = false
                this.editDocumentId = false
                this.editCredentials = false
                this.editProfile = false
                this.editPersonalData = false
                this.editCV = false
            }
        },
        getUserTypes(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/users/types/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.userTypes = res.data
                            this.spinnerUser = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerUser = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerUser = false
                            this.userTypes = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerUser = false
                            reject(err)
                        })
                }

            })
        },
        getUser(id, dateParam = '') {
            return new Promise((resolve, reject) => {
                if (id !== 0) {
                    const config = {
                        headers: {
                            Authorization: 'Token ' + useAuthStore().userGetters.token
                        }
                    }
                    this.spinnerUser = true
                    axios.get('api/users/' + id + '/?date=' + dateParam.toString() + '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone, config)
                        .then(res => {
                            this.user = res.data
                            resolve(res)
                            this.spinnerUser = false
                            this.user.profile_frontend = this.user.profile_path ? this.user.profile_path : profileImage

                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerUser = false
                            reject(err)
                        })
                }
            })
        },
        getUsers(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationUser
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/users/')

                this.spinnerUser = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.users = res.data
                            this.spinnerUser = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerUser = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerUser = false
                            this.users = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerUser = false
                            reject(err)
                        })
                }

            })
        },
        editUser(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/users/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerUser = false
                        reject(err)
                    })
            })
        },
        addUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/users/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerUser = false
                        reject(err)
                    })
            })

        },

        deleteUser(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/users/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        changePassword(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/users/change-password/', {
                    old_password: payload.old_password,
                    password: payload.password,
                    confirmed_password: payload.confirmed_password,
                }, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        setPassword(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/users/' + payload.id + '/set-password/', {
                    password: payload.values.password,
                    confirmed_password: payload.values.confirmed_password
                }, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        requestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.post('api/users/request-reset-password/', {
                    email: payload
                }).then(res => {
                    resolve(res)
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                    this.spinnerUser = false
                }, reason => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                    this.spinnerUser = false
                }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        checkRequestResetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.get('api/users/check-request-reset-password/' + payload.uidb64 + '/' + payload.token)
                    .then(res => {
                        resolve(res)
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    router.push('/login').catch(() => {
                    })
                    reject(err)
                })
            })
        },
        resetPassword(payload) {
            return new Promise((resolve, reject) => {
                axios.patch('api/users/reset-password/', {
                    password: payload.password,
                    token: payload.token,
                    uidb64: payload.uidb64
                }).then(res => {
                    resolve(res)
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                    this.spinnerUser = false
                }, reason => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                    this.spinnerUser = false
                }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        deactivateUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerUser = true
                axios.get('api/users/' + payload + '/deactivate/', config)
                    .then(res => {
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        }
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        },
        activateUser(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerUser = true

                axios.get('api/users/' + payload + '/activate/', config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerUser = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerUser = false
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerUser = false
                    reject(err)
                })
            })
        }
    },
    getters: {
        usersGetters: state => {
            return state.users
        },
        userGetters: state => {
            return state.user
        },
        spinnerUserGetters: state => {
            return state.spinnerUser
        },
        paginationUserGetters: state => {
            return state.paginationUser
        },
        userTypesGetters: state => {
            return state.userTypes
        }
    }
})