<template>
  <div class="container-popup-custom position-sticky">
    <div :class="{'border-logout': storeApi.typePopupGetters === 'logout'}" class="container-content-popup"
         v-if="storeApi.typePopupGetters === 'logout'">
      <span class="title-medium">Sei sicuro di voler uscire dal portale?</span>
      <div class="container-buttons">
        <span role="button" @click="resetStates" class="btn btn-outline-danger">Annulla</span>
        <button @click="logout" class="btn btn-primary">Esci dal portale</button>
      </div>
    </div>
    <div class="container-content-popup container-content-popup-actions container-content-popup-actions-success"
         v-else-if="storeApi.typePopupGetters === 'success'">
      <span>{{ storeApi.successMessage }}</span>
    </div>
    <div class="container-content-popup container-content-popup-actions container-content-popup-actions-danger"
         v-else-if="storeApi.typePopupGetters === 'danger'">
      <span v-if="!storeApi.popUpMessageGetters">{{ storeApi.errorMessage }}</span>
      <span v-else>
        <span v-for="message in formattedMessage" :key="message"> {{ message }}<br> </span>
        </span>
    </div>
  </div>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {useAuthStore} from "@/stores/auth";

export default {
  name: "ComponentPopup",
  setup() {
    const storeApi = useApiSettingStore()
    return {
      storeApi
    }
  },
  computed: {
    formattedMessage() {
      return this.getAllStringFromArrays(this.storeApi.popUpMessageGetters)
    }
  },
  created() {
  },
  methods: {
    getAllStringFromArrays(value, currentList = []) {
      if (!Array.isArray(value)) {
        return currentList.concat([value]);
      }
      try {
        for (const element of value) {
          currentList = this.getAllStringFromArrays(element, currentList);
        }
        // eslint-disable-next-line no-empty
      } catch (error) {
      }
      return currentList;
    },

    resetStates() {
      useApiSettingStore().typePopup = ''
      useApiSettingStore().overlayPopup = false
    },
    logout() {
      useAuthStore().$reset()
      useApiSettingStore().$reset()
      this.resetStates()
      this.$router.push({name: 'login'}).then(() => {
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
.container-content-popup-actions-success.container-content-popup-actions.container-content-popup {
  color: #155724;
  background-color: #d4edda;
  align-items: center;
  border-color: #c3e6cb !important;
  border-radius: .25rem;
  text-align: center;
  font-size: x-large;
}

.container-content-popup-actions-danger.container-content-popup-actions.container-content-popup {
  color: #721c24;
  background-color: #f8d7da;
  align-items: center;
  border-color: #f5c6cb !important;
  border-radius: .25rem;
  text-align: center;
  font-size: x-large;
}

.text-decoration-underline {
  cursor: pointer;
}

.title-medium {
  text-align: center;
  font-size: x-large;
}

.container-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.border-logout {
  border: 1px solid var(--color-f6f6f7) !important;
}


.container-content-popup {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  border: 1px solid var(--color-primary) !important;
  width: 50%;
  justify-content: center;
  padding: 30px 25px;
  color: #004085;
  background: #f1f6fb;
  position: relative;
  margin: auto;
}

.btn-primary {
  background: var(--color-primary);
  border-color: #67c0e1;
}

.btn-light {
  border-color: #c6c8ca;
}

/*// X-Small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {

}

/*// Small devices (landscape phones, less than 768px)*/
@media (max-width: 767.98px) {
  .container-content-popup {
    width: 90%;
  }

}

/*// Medium devices (tablets, less than 992px)*/
@media (max-width: 991.98px) {

}

/*// Large devices (desktops, less than 1200px)*/
@media (max-width: 1199.98px) {
}

/*// X-Large devices (large desktops, less than 1400px)*/
@media (max-width: 1399.98px) {
}

</style>