import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {isNotIntegerOrNull} from "@/main";
import {ShiftTimetableCreateUpdate} from "@/models/shift";
import {format} from "date-fns";

export const useShiftStore = defineStore("shift", {
    state: () => ({
        shiftTimetables: null,
        shiftTimetable: ShiftTimetableCreateUpdate,
        spinnerShiftTimetable: false,
        editSubstitute: false,
        addShift: false,
        queryParamsUncover: {search: '', page: 1},
        queryParamsCalendar: {view: 'month', focus: format(new Date, 'yyyy-MM-dd')},
        queryParams: {
            search: '', page: 1, employee: '', client: '',
            starts_at__range: '', ends_at__range: '', date: '', date__lte: '', date__gte: ''
        },
        paginationShiftTimetable: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setQueryParamsUncover(queryParams = null) {
            if (queryParams) {
                this.queryParamsUncover = queryParams
            } else {
                this.queryParamsUncover = {
                    search: '',
                    page: 1
                }
            }
        },
        setQueryParamsCalendar(queryParams = null) {
            if (queryParams) {
                this.queryParamsCalendar = queryParams
            } else {
                this.queryParamsCalendar = {view: 'month', focus: format(new Date, 'yyyy-MM-dd')}
            }
        },
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    search: '', page: 1, employee: '', client: '',
                    starts_at__range: '', ends_at__range: '', date: '', date__lte: '', date__gte: ''
                }
            }
        },
        toggleEditSubstitute(editSubstitute = null) {
            this.editSubstitute = editSubstitute === null ? !this.editSubstitute : editSubstitute
        },

        toggleAddShift(addShift = null) {
            this.addShift = addShift === null ? !this.addShift : addShift
        },

        setShiftTimetablePagination(shiftTimetablePagination) {
            this.paginationShiftTimetable = shiftTimetablePagination
        },

        setSpinner(isSpinning) {
            this.spinnerShiftTimetable = isSpinning
        },

        getShiftTimetable(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.get('api/shift-timetables/' + id + '/', config)
                    .then(res => {
                        this.shiftTimetable = res.data
                        resolve(res)
                        this.spinnerShiftTimetable = false

                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerShiftTimetable = false
                        reject(err)
                    })
            })
        },

        getEmployeeCalendar(id, payload = {}) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            payload.queryParams.page_size = null
            payload.queryParams.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/employee-calendar/' + id + '/')
                this.spinnerShiftTimetable = true
                let params = new URLSearchParams()
                for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                    params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                }
                url.search = params
                axios.get(url.pathname + url.search, config)
                    .then(res => {
                        this.shiftTimetables = res.data
                        resolve(res)
                        this.spinnerShiftTimetable = false

                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerShiftTimetable = false
                        reject(err)
                    })

            })
        },

        getShiftTimetables(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationShiftTimetable
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/shift-timetables/')

                this.spinnerShiftTimetable = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    // for (let queryParam in payload.queryParams) {
                    //     if (queryParam === 'starts_at__range' || queryParam === 'ends_at__range') {
                    //         if (payload.queryParams[queryParam]) {
                    //             params.set(queryParam, UTCTime(payload.queryParams[queryParam]))
                    //         }
                    //     } else {
                    //         params.set(queryParam, payload.queryParams[queryParam])
                    //     }
                    // }
                    for (let queryParam in payload.queryParams) {
                        params.set(queryParam, payload.queryParams[queryParam])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.shiftTimetables = res.data
                            resolve(res)
                            this.spinnerShiftTimetable = false

                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerShiftTimetable = false
                            this.shiftTimetables = res.data
                            resolve(res)
                            this.spinnerShiftTimetable = false

                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                }

            })
        },

        getAllShiftTimetableIds(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/shift-timetables/get-all-ids/')

                this.spinnerShiftTimetable = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.spinnerShiftTimetable = false
                            resolve(res)
                        }, reason => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                            this.spinnerShiftTimetable = false
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerShiftTimetable = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                }

            })
        },

        getShiftUpdateTypeChoices() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/shift-timetables/update-type-choices/')

                axios.get(url.pathname, config)
                    .then(res => {
                        this.employees = res.data
                        resolve(res)
                    }).catch(err => {
                    useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                    this.spinnerShiftTimetable = false
                    reject(err)
                })
            })
        },

        getUncoveredShiftTimetables(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationShiftTimetable
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/shift-timetables/uncovered/')

                this.spinnerShiftTimetable = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.spinnerShiftTimetable = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerShiftTimetable = false
                            resolve(res)
                        })
                        .catch(err => {
                            useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                            this.spinnerShiftTimetable = false
                            reject(err)
                        })
                }

            })
        },
        editShiftTimetable(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.patch('api/shift-timetables/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })
        },
        editShiftSubstitute(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/shift-timetables/' + payload.id + '/substitute/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })
        },

        editBulkShiftTimetable(payload, ids = null) {
            payload.ids = ids
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/shift-timetables/bulk-update/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })
        },
        getBulkShiftTimetable(payload = null) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.post('api/shift-timetables/get-bulk-update/', payload, config)
                    .then(res => {
                        this.spinnerShiftTimetable = false
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerShiftTimetable = false
                        reject(err)
                    })
            })
        },

        setCancelledByClient(payload = null) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.post('api/shift-timetables/set-cancelled-by-client/', payload, config)
                    .then(res => {
                        this.spinnerShiftTimetable = false
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerShiftTimetable = false
                        reject(err)
                    })
            })
        },
        addShiftTimetable(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.post('/api/shift-timetables/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })

        },

        addShiftFromDefaultWeek(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerShiftTimetable = true
                axios.post('/api/shift-timetables/create-from-default-week/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })

        },


        deleteShiftTimetable(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/shift-timetables/' + id + '/', config)
                    .then(res => {
                        this.spinnerShiftTimetable = false
                        resolve(res)
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        this.spinnerShiftTimetable = false
                        reject(err)
                    })
            })
        },


        bulkDeleteShiftTimetable(payload, ids = null) {
            payload.ids = ids
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('api/shift-timetables/bulk-delete/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().successCode, useApiSettingStore().successMessage)
                        this.spinnerShiftTimetable = false
                    }, reason => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, (reason.response?.status === 500 || reason.response?.status === 404) ? useApiSettingStore().errorMessage : reason.response.data)
                        this.spinnerShiftTimetable = false
                    })
                    .catch(err => {
                        useApiSettingStore().setSuccessOrErrorMessage(useApiSettingStore().errorCode, useApiSettingStore().errorMessage)
                        reject(err)
                        this.spinnerShiftTimetable = false
                    })
            })
        },
    },
    getters: {
        shiftTimetablesGetters: state => {
            return state.shiftTimetables
        },
        shiftTimetableGetters: state => {
            return state.shiftTimetable
        },
        spinnerShiftTimetableGetters: state => {
            return state.spinnerShiftTimetable
        },
        paginationShiftTimetableGetters: state => {
            return state.paginationShiftTimetable
        }
    }
})