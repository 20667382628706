import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {isNotIntegerOrNull} from "@/main";

export const useNotificationStore = defineStore("notification", {
    state: () => ({
        notifications: null,
        notification: null,
        spinnerNotification: false,
        unreadMessages: 0,
        paginationNotification: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setNotificationPagination(notificationPagination) {
            this.paginationNotification = notificationPagination
        },
        getUnread() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.get('api/notifications/unread/', config)
                    .then(res => {
                        this.unreadMessages = res.data
                        resolve(res)
                        this.spinnerNotification = false
                    })
                    .catch(err => {
                        this.unreadMessages = 0
                        this.spinnerNotification = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        getNotification(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerNotification = true
                axios.get('api/notifications/' + id + '/', config)
                    .then(res => {
                        this.notification = res.data
                        resolve(res)
                        this.spinnerNotification = false

                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            this.spinnerNotification = false
                        }
                        reject(err)
                    })
            })
        },
        getNotifications(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationNotification
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/notifications/')

                this.spinnerNotification = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.notifications = res.data
                            this.spinnerNotification = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerNotification = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerNotification = false
                            this.notifications = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerNotification = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },
        deleteNotification(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/notifications/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
    },
    getters: {
        notificationsGetters: state => {
            return state.notifications
        },
        notificationGetters: state => {
            return state.notification
        },
        spinnerNotificationGetters: state => {
            return state.spinnerNotification
        },
        paginationNotificationGetters: state => {
            return state.paginationNotification
        },
        unreadMessagesGetters: state => {
            return state.unreadMessages
        }
    }
})