import {defineStore} from 'pinia'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {Service} from "@/models/service.js";
import {isNotIntegerOrNull} from "@/main";

export const useServiceStore = defineStore("service", {
    state: () => ({
        services: null,
        service: Service,
        spinnerService: false,
        queryParams: {search: '', page: 1},
        paginationService: Number(process.env['VUE_APP_PER_PAGE']),
    }),
    actions: {
        setQueryParams(queryParams = null) {
            if (queryParams) {
                this.queryParams = queryParams
            } else {
                this.queryParams = {
                    search: '',
                    page: 1
                }
            }
        },

        setServicePagination(servicePagination) {
            this.paginationService = servicePagination
        },

        getService(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerService = true
                axios.get('api/services/' + id + '/', config)
                    .then(res => {
                        this.service = res.data
                        resolve(res)
                        this.spinnerService = false

                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            this.spinnerService = false
                        }
                        reject(err)
                    })
            })
        },
        getServices(payload) {
            if (!payload.queryParams) {
                payload.queryParams = {}
            }
            if (isNotIntegerOrNull(payload.queryParams.page_size)) {
                payload.queryParams.page_size = this.paginationService
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/services/')

                this.spinnerService = true
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.services = res.data
                            this.spinnerService = false
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerService = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.spinnerService = false
                            this.services = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            this.spinnerService = false
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }

            })
        },

        getAllServices() {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                let url = new URL(axios.defaults.baseURL + 'api/services/list-all/')
                this.spinnerService = true
                axios.get(url.pathname, config)
                    .then(res => {
                        this.spinnerService = false
                        resolve(res)
                    })
                    .catch(err => {
                        this.spinnerService = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })

            })
        },

        editService(payload, id = null) {
            if (id === null) {
                id = payload.id
            }
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.patch('api/services/' + id + '/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },
        addService(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.post('/api/services/', payload, config)
                    .then(res => {
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })

        },

        deleteService(id) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                axios.delete('/api/services/' + id + '/', config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },


        deactivateService(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerService = true
                axios.get('api/services/' + payload + '/deactivate/', config)
                    .then(res => {
                        this.spinnerService = false
                        resolve(res)
                        if (!res.data.error) {
                            useApiSettingStore().overlayPopup = true
                            useApiSettingStore().typePopup = 'success'
                            setTimeout(() => {
                                useApiSettingStore().overlayPopup = false
                                useApiSettingStore().typePopup = ''
                                useApiSettingStore().popUpMessage = null
                            }, 1000)
                        }
                    }, reason => {
                        resolve(reason)
                        this.spinnerService = false
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        this.spinnerService = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        },

        activateService(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }
                this.spinnerService = true

                axios.get('api/services/' + payload + '/activate/', config)
                    .then(res => {
                        this.spinnerService = false
                        resolve(res)
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'success'
                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 1000)
                    }, reason => {
                        this.spinnerService = false
                        useApiSettingStore().overlayPopup = true
                        useApiSettingStore().typePopup = 'danger'
                        useApiSettingStore().popUpMessage = reason.response.data

                        setTimeout(() => {
                            useApiSettingStore().overlayPopup = false
                            useApiSettingStore().typePopup = ''
                            useApiSettingStore().popUpMessage = null
                        }, 3000)
                    })
                    .catch(err => {
                        this.spinnerService = false
                        if (err.response && err.response.status === 500) {
                            useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                        }
                        reject(err)
                    })
            })
        }
    },
    getters: {
        servicesGetters: state => {
            return state.services
        },
        serviceGetters: state => {
            return state.service
        },
        spinnerServiceGetters: state => {
            return state.spinnerService
        },
        paginationServiceGetters: state => {
            return state.paginationService
        }
    }
})