import {createApp} from 'vue'
import App from './App.vue'
import router from "@/router";
import BootstrapVue3 from 'bootstrap-vue-3'
import {vBPopover, vBTooltip} from "bootstrap-vue-next";
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/css/main.css'
import './assets/css/vue-calendar.css'
import './assets/css/vuecal.css'
import mitt from 'mitt';
import {createPinia} from 'pinia'
import VueDatePicker from '@vuepic/vue-datepicker';
import {createPersistedStatePlugin} from 'pinia-plugin-persistedstate-2'
import axios from "axios";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import '@vuepic/vue-datepicker/dist/main.css'
import "vue3-select/dist/vue3-select.css";
import {parseISO, setHours, setMinutes} from "date-fns";
import dayjs from "dayjs";
import CountUp from 'vue-countup-v3';


const pinia = createPinia()
const emitter = mitt();

// create an .env.local file to store the local backend address. This will prevent the setting from being tracked on git
// for more environment variables, if you are using Vue cli 3, only variables that start with VUE_APP_ will be loaded.
// An env file for a specific mode (e.g. .env.production) will take higher priority than a generic one (e.g. .env).
axios.defaults.baseURL = process.env['VUE_APP_BACKEND_HOST']
axios.interceptors.response.use(undefined, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    if (error.message === 'Network Error') {
        useApiSettingStore().errorNetwork = true
    }
    return Promise.reject(error);
})

// calcolo le altezze di ogni singola classe per settare i top e il position sticky
export function setStickyTop(navbar, span, filter, thead) {
    if (navbar && span) {
        span.style.position = 'sticky'
        span.style.background = '#292929'
        span.style.top = navbar.getBoundingClientRect().height + 'px'
        if (filter) {
            filter.style.position = 'sticky'
            filter.style.background = '#292929'
            filter.style.top = navbar.getBoundingClientRect().height + span.getBoundingClientRect().height + 'px'
            if (thead) {
                thead.style.position = 'sticky'
                thead.style.top = navbar.getBoundingClientRect().height + filter.getBoundingClientRect().height + span.getBoundingClientRect().height + 'px'
            }
        }

    }
}

export function openSidebar() {
    let sidebar_app = document.getElementById('sidebar-app')
    if (sidebar_app) {
        if (window.matchMedia("(orientation: portrait)").matches) {
            sidebar_app.style.width = '80vw'
        } else {
            let toggle_open = document.getElementsByClassName('toggle-open')[0]
            toggle_open.style.display = 'none'
            let sidebar = document.getElementsByClassName('toggle-class')[0]
            sidebar.style.display = 'block'
            sidebar_app.style.width = 'auto'
        }
    }
}

export function isNotIntegerOrNull(value) {
    return value !== null && !Number.isInteger(value);
}

export function localizeDatetimeFromDateAndTime(date, time) {
    let dateTime = date;
    if (typeof date !== typeof new Date()) {
        dateTime = parseISO(date)
    }
    if (typeof time === typeof 'string') {
        time = {
            hours: time.split(':', 0),
            minutes: time.split(':', 1)
        }
    }
    dateTime = setHours(dateTime, time['hours'])
    dateTime = setMinutes(dateTime, time['minutes'])
    return dateTime;
}

export function UTCTime(time) {
    let dateTime = new Date();
    if (typeof time === typeof 'string') {
        time = {
            hours: time.split(':')[0],
            minutes: time.split(':')[1]
        }
    }
    dateTime.setHours(time['hours'])
    dateTime.setMinutes(time['minutes'])
    let hoursString = dateTime.getUTCHours().toString()
    let minutesString = dateTime.getUTCMinutes().toString()
    if (hoursString.length === 1) {
        hoursString = '0' + hoursString
    }
    if (minutesString.length === 1) {
        minutesString = '0' + minutesString
    }
    return `${hoursString}:${minutesString}`;
}

export function localizeDatetimeFunction(value) {
    let date = new Date('1998-03-15T00:30:00')
    if (typeof value === typeof 'string') {
        date = new Date(value);
    } else {
        date.setFullYear(value.getDate())
        date.setTime(value.getTime())
    }
    return dayjs(date, "yyyy-MM-dd'T'HH:mmzzz");
}

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(router)
app.directive('b-tooltip', vBTooltip)
app.directive('b-popover', vBPopover)
app.use(BootstrapVue3)
app.use(pinia)
app.component('CountUp', CountUp);
app.component('VueDatePicker', VueDatePicker);
pinia.use(createPersistedStatePlugin())
app.mount('#app')