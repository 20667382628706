<template>
  <div class="d-flex col">
    <div class="container-navbar-nav-buttons" v-show="isLandscape">
      <img role="button" src="../../assets/sidebar/toggle.svg" class="primary-color toggle-open me-2 p-3" alt="Toggle"
           @click="openSidebar">
    </div>
    <div class="container-navbar" v-show="isLandscape">
      <img v-if="storeAuth.userPermissionsGetters.employees &&
                 storeAuth.userPermissionsGetters.employees.includes('view') &&
                 storeAuth.userPermissionsGetters.employees.includes('retrieve') &&
                 storeAuth.userPermissionsGetters.clients &&
                 storeAuth.userPermissionsGetters.clients.includes('view') &&
                 storeAuth.userPermissionsGetters.clients.includes('list') &&
                 storeAuth.userPermissionsGetters.services &&
                 storeAuth.userPermissionsGetters.services.includes('view') &&
                 storeAuth.userPermissionsGetters.services.includes('list')"
           class="me-2 mh-100 p-2" role="button" src="../../assets/navbar/search.svg" alt="Search">
      <input
          v-if="storeAuth.userPermissionsGetters.employees &&
                storeAuth.userPermissionsGetters.employees.includes('view') &&
                storeAuth.userPermissionsGetters.employees.includes('retrieve') &&
                storeAuth.userPermissionsGetters.clients &&
                storeAuth.userPermissionsGetters.clients.includes('view') &&
                storeAuth.userPermissionsGetters.clients.includes('list') &&
                storeAuth.userPermissionsGetters.services &&
                storeAuth.userPermissionsGetters.services.includes('view') &&
                storeAuth.userPermissionsGetters.services.includes('list')"
          @focus="inputFocused = true"
          @blur="inputFocused = false"
          v-model="searchTerm" type="text" placeholder="Cerca qui" class="form-control p-2 w-100" @keyup="searchGeneral">
      <ul
          v-if="valuesList.length > 0 && inputFocused"
          class="mw-100 rounded bg-white border color-gray-707070 px-2"
          style="margin-top: 48px;list-style-type: none; z-index: 13; position: absolute;">
        <li
            v-for="search in valuesList"
            :key="search"
            @mousedown="redirectTo(search.path)"
            class="list-services mw-100">
          <b>{{ search.section }}</b>: {{ search.search_result }}
        </li>
      </ul>

      <img v-if="storeNotification.unreadMessagesGetters === 0"
           @click="goToPath('notifications')" role="button" class="me-2 mh-100 p-2" style="float: right !important;"
           src="../../assets/navbar/notification.svg"
           alt="Notification">
      <div class="d-flex position-relative">
        <img v-if="storeNotification.unreadMessagesGetters > 0" style="float: right !important;"
             @click="goToPath('notifications')" role="button" class="me-2 mh-100 p-2"
             src="../../assets/navbar/notification-active.svg"
             alt="Notification">
        <span role="button" @click="goToPath('notifications')" v-if="storeNotification.unreadMessagesGetters > 0"
              class="sup badge alert-danger">{{ storeNotification.unreadMessagesGetters }}</span></div>
      <img role="button" class="me-2 mh-100 p-2" src="../../assets/navbar/logout.svg" alt="Esci" @click="logout">
    </div>
  </div>
  <div class="header-nav-bar" v-show="!isLandscape" :class="{'header_nav_bar': changeCss()}" id="header-nav-bar">
    <div class="d-flex justify-content-between primary-color-bg" v-if="$route.name === 'detailUser'">
      <img role="button" src="../../assets/sidebar/toggle-white.svg" class="primary-color toggle-open me-2 p-2"
           alt="Toggle" @click="openSidebar">
    </div>
    <div class="d-flex justify-content-between navbar-background" v-else>
      <img role="button" v-if="$route.name === 'profile'" src="../../assets/sidebar/toggle-white.svg" class="primary-color toggle-open me-2 p-2" alt="Toggle"
           @click="openSidebar">
      <img role="button" v-else src="../../assets/sidebar/toggle.svg" class="primary-color toggle-open me-2 p-2" alt="Toggle"
           @click="openSidebar">
      <div v-if="!isLandscape" class="d-flex position-relative">
        <img v-if="$route.name === 'profile'"
             @click="goToPath('notifications')" role="button" class="me-2 mh-100 p-2"
             src="../../assets/navbar/notification-active-white.svg"
             alt="Notification">
        <img v-else
             @click="goToPath('notifications')" role="button" class="me-2 mh-100 p-2"
             src="../../assets/navbar/notification-active.svg"
             alt="Notification">
        <span role="button" @click="goToPath('notifications')" v-if="storeNotification.unreadMessagesGetters > 0"
              class="sup badge alert-danger">{{ storeNotification.unreadMessagesGetters }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {openSidebar} from "@/main";
import {useNotificationStore} from "@/stores/notifications";

export default {
  name: "ComponentNavBar",

  setup() {
    const storeAuth = useAuthStore()
    const storeNotification = useNotificationStore()
    const storeApi = useApiSettingStore()

    return {
      storeNotification,
      storeAuth,
      storeApi
    }
  },
  data() {
    return {
      isLandscape: window.innerWidth > window.innerHeight,
      showNotification: false,
      searchTerm: '',
      valuesList: [],
      inputFocused: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)

    let toggle_open = document.getElementsByClassName('toggle-open')[0]
    if (toggle_open)
      toggle_open.style.display = 'none'
  },
  watch: {
    isLandscape: {
      handler: function (value) {
        if (value) {
          let toggle_open = document.getElementsByClassName('toggle-open')[0]
          if (toggle_open)
            toggle_open.style.display = 'none'
        }

        this.openSidebar()
      }
    }
  },
  methods: {
    searchGeneral() {
      if (this.searchTerm === '') {
        this.valuesList = []
      } else {
        this.storeApi.getGeneralSearch(this.searchTerm).then(res => {
          this.valuesList = res.data
        })
      }
    },

    redirectTo(path) {
      this.$router.push('/' + path)
    },

    handleResize() {
      this.isLandscape = window.innerWidth > window.innerHeight
    },
    openSidebar,
    goToPath(name) {
      if (name === 'profile') {
        this.$router.push({name: name, params: {id: useAuthStore().userGetters.user_id}}).catch(() => {
        })
      } else {
        this.$router.push({name: name, params: {}}).catch(() => {
        })
      }
    },
    logout() {
      useApiSettingStore().typePopup = 'logout'
      useApiSettingStore().overlayPopup = true
    },
    changeCss() {
      return (!this.isLandscape && this.$route.name === 'detailUser') || (!this.isLandscape && this.$route.name === 'profile')
    },
  },
  computed: {}
}
</script>

<style scoped>

.alert-danger.sup.badge {
  color: #58151c;
  background-color: #F8D7DAFF;
  border-color: #842029FF;
}

.form-control {
  border: 0;
}

.container-single-navbar-notification img {
  margin-left: 10px;
}

.sup {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 8px;
}

.container-navbar-nav-buttons {
  max-height: 80px;
  display: flex;
  z-index: 10;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
}

.container-navbar {
  padding: 10px;
  height: 60px;
  display: flex;
  width: 100%;
  z-index: 10;
  background: white;
  position: sticky;
  top: 0;
  left: 0;
}

.header-nav-bar {
  background: unset;
}

.header_nav_bar {
  background: var(--color-primary);
}
</style>